html,
body {
  margin: 0px;
  height: 100%;
  color: "#293056";
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

#root {
  height: 100%;
  color: "#293056";
}

p {
  color: "#293056";
}

button {
  color: "#293056";
}

div {
  color: "#293056";
}

.audio-react-recorder {
  width: 100%;
  height: 6em;
}

.audio-react-recorder__canvas {
  width: 100%;
  height: 100%;
}
